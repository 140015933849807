import React, { useState, useEffect } from "react";
import styles_b from "../styles/ziprenewal_b.module.css";
import axios from "axios";
import heroImage from "../assets/shutterstock_2360064203.jpg";
import logo from "../assets/square_logo.png";
import { initFacebookPixel, trackEvent } from "../utils/facebook_pixel";
import { useLocation } from "react-router-dom";
// import SEO from "../SEO";

const ZipRenewalB = () => {
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [email, setEmail] = useState(""); // email state

  const location = useLocation();

  const ad = "hassle-free";
  const campaign = "ZipRenewal Discovery Campaign";

  useEffect(() => {
    initFacebookPixel();
  }, []);
  useEffect(() => {
    trackEvent("PageView", {
      page: location.pathname,
      ad_id: ad,
      campaign_id: campaign,
    });

    // trackEvent("PageViewA", {
    //   page: location.pathname,
    //   ad_id: ad,
    //   campaign_id: campaign,
    // });
  }, [location.pathname]);

  const handleClick = async () => {
    if (!email) {
      trackEvent("ProcessButtonClick", {
        content_name: "View Process Button",
        ad_id: ad,
        campaign_id: campaign,
      });

      setShowModal(true);
      return;
    }

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(email)) {
      alert("Please enter a valid email address.");
      setEmail("");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/emailApi/email`,
        {
          email,
          actionType: "renewal-process",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setEmail("");
        window.location.href = "/process"; // Redirect to '/process' on click
      }
    } catch (error) {
      console.error("Error storing email:", error);
    }
  };

  const handleEmailSubmit = () => {
    trackEvent("ProcessEmailButtonClick", {
      content_name: "Provided Email Button",
      ad_id: ad,
      campaign_id: campaign,
    });

    setShowModal(false);
    handleClick();
  };
  return (
    <>
      {/* <SEO
        title="Renew Your Trademark in Minutes, Hassle-Free | ZipRenewal"
        description="Simplify trademark renewals, manage infringement monitoring, and ensure reliable DMCA compliance. Start your protection journey with ZipRenewal."
        keywords="trademark renewal, trademark registration, infringement monitoring, DMCA compliance, brand protection"
      /> */}
      <header className={styles_b.header}>
        <div className={styles_b.logo}>
          <img
            src={logo}
            alt="Logo"
            style={{ width: "200px", height: "auto" }}
          />
        </div>
        <nav className={styles_b.navMenu} style={{ display: "none" }}>
          <a href="/home" className={styles_b.navLink}>
            Home
          </a>
          <a href="/how-it-works" className={styles_b.navLink}>
            How It Works
          </a>
          <a href="/pricing" className={styles_b.navLink}>
            Pricing
          </a>
          <a href="/testimonials" className={styles_b.navLink}>
            Testimonials
          </a>
          <a href="/contact" className={styles_b.navLink}>
            Contact
          </a>
          <button className={styles_b.ctaButton}>Start Now</button>
        </nav>
      </header>

      <section className={styles_b.hero}>
        <div className={styles_b.heroContent}>
          <h1>Renew Your Trademark in Minutes, Hassle-Free</h1>
          <h2>The fastest way to keep your brand protected.</h2>
          <p>
            Skip the paperwork and high costs. Renew quickly so you can focus on
            your business.
          </p>
          <button className={styles_b.primaryCta} onClick={handleClick}>
            Let Us Show You How!
          </button>
        </div>
        <div className={styles_b.heroImage}>
          <img src={heroImage} alt="Business owner working" />
        </div>
      </section>

      <section className={styles_b.valueProposition}>
        <div className={styles_b.valueItem}>
          <img
            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/documents-1699092-1449731.png?f=webp&w=256"
            alt="Document Icon"
          />
          <p>
            Affordable, Simple Trademark Renewal: Streamline the renewal process
            in just a few steps.
          </p>
        </div>
        <div className={styles_b.valueItem}>
          <img
            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/shield-6403655-5388095.png?f=webp&w=256"
            alt="Shield Icon"
          />
          <p>
            Peace of Mind Monitoring: Stay compliant and informed with proactive
            updates.
          </p>
        </div>
        <div className={styles_b.valueItem}>
          <img
            src="https://cdn.iconscout.com/icon/premium/png-512-thumb/dollar-1111-891546.png?f=webp&w=256"
            alt="Dollar Icon"
          />
          <p>
            Confidence in Brand Protection: Safeguard your brand without the
            high costs of law firms.
          </p>
        </div>
      </section>

      <section className={styles_b.testimonial}>
        <p>
          "ZipRenewal made renewing my trademark simple and stress-free. I
          didn’t need a lawyer, and my brand is protected!"
        </p>
        <div className={styles_b.trustLogos} style={{ display: "none" }}>
          <img src="publication-logo1.png" alt="Publication 1" />
          <img src="publication-logo2.png" alt="Publication 2" />
          <img src="publication-logo3.png" alt="Publication 3" />
        </div>
      </section>
      {/* Modal for Email Input */}
      {showModal && (
        <div className={styles_b.modal}>
          <div className={styles_b.modalContent}>
            {/* Close button */}
            <button
              className={styles_b.closeButton}
              onClick={() => setShowModal(false)}
            >
              ✖
            </button>
            <h2>Please Enter your email</h2>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleEmailSubmit();
                }
              }}
            />
            <button
              className={styles_b.submitButton}
              onClick={handleEmailSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <footer className={styles_b.footer}>
        <p>Your brand deserves affordable, hassle-free protection.</p>
        <br />
        <a href="/how-it-works" className={styles_b.footerLink}>
          How ZipRenewal Simplifies Trademark Protection
        </a>
      </footer>
    </>
  );
};

export default ZipRenewalB;
