import React, { useState, useEffect } from "react";
import axios from "axios";
import home_style from "../styles/home.module.css";
import process from "../assets/process.png";
import secure from "../assets/secure.png";
import support from "../assets/support.png";
import logo from "../assets/New_Logo.png";
import statusCodes from "../statusCodes.json"; // Trademark status codes
import { initFacebookPixel, trackEvent } from "../utils/facebook_pixel";
import { useLocation } from "react-router-dom";
// import SEO from "../SEO";

const IndexPage = () => {
  const [serialNumber, setSerialNumber] = useState("");
  const [email, setEmail] = useState(""); // New state for email
  const [registrationDate, setRegistrationDate] = useState("");
  const [tm5Status, setTm5Status] = useState("");
  const [tm5StatusDesc, setTm5StatusDesc] = useState("");
  const [tm5StatusDef, setTm5StatusDef] = useState("");
  const [statusText, setStatusText] = useState("");
  const [initialRenewal, setInitialRenewal] = useState("");
  const [subsequentRenewal, setSubsequentRenewal] = useState("");
  const [showCard, setShowCard] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState(null);

  const activeStatusCodes = statusCodes.activeStatusCodes;
  const notActiveStatusCodes = statusCodes.notActiveStatusCodes;

  const location = useLocation();

  const ad = "search";
  const campaign = "ZipRenewal Discovery Campaign";

  useEffect(() => {
    initFacebookPixel();
  }, []);
  useEffect(() => {
    trackEvent("PageView", {
      page: location.pathname,
      ad_id: ad,
      campaign_id: campaign,
    });

    // trackEvent("PageViewA", {
    //   page: location.pathname,
    //   ad_id: ad,
    //   campaign_id: campaign,
    // });
  }, [location.pathname]);

  // Function to calculate renewal dates
  const calculateRenewalDates = (registrationDate) => {
    const regDate = new Date(registrationDate);
    const currentDate = new Date();

    if (isNaN(regDate.getTime())) {
      return { initial: "Invalid Date", subsequent: "Invalid Date" };
    }

    // Initial renewal: 5-6 years after the registration date
    const initialStart = new Date(regDate);
    initialStart.setFullYear(initialStart.getFullYear() + 5);

    const initialEnd = new Date(regDate);
    initialEnd.setFullYear(initialEnd.getFullYear() + 6);

    // Check if current date is before, during, or after the initial renewal window
    let initialRenewalPeriod = null;
    if (currentDate <= initialEnd) {
      // If the current date is before or within the initial window, display it
      initialRenewalPeriod = `${initialStart.toDateString()} - ${initialEnd.toDateString()}`;
    }

    // If the initial renewal has passed, find the next subsequent renewal window
    let nextSubsequentRenewalPeriod = null;
    if (currentDate > initialEnd) {
      let subsequentStart, subsequentEnd;

      // Subsequent renewals: Every 10 years, with a 9-10 year window
      for (let i = 1; i <= 10; i++) {
        subsequentStart = new Date(regDate);
        subsequentEnd = new Date(regDate);

        // Start date is 9 years from registration + (i - 1) * 10
        subsequentStart.setFullYear(regDate.getFullYear() + 9 + (i - 1) * 10);

        // End date is 10 years from registration + (i - 1) * 10
        subsequentEnd.setFullYear(regDate.getFullYear() + 10 + (i - 1) * 10);

        // Find the first subsequent renewal window where current date fits or is in the future
        if (currentDate <= subsequentEnd) {
          nextSubsequentRenewalPeriod = `${subsequentStart.toDateString()} - ${subsequentEnd.toDateString()}`;
          break;
        }
      }

      // If no renewal period is found after the loop, return "No Renewal Periods Found"
      if (!nextSubsequentRenewalPeriod) {
        nextSubsequentRenewalPeriod = "No Renewal Periods Found";
      }
    }

    return {
      initial: initialRenewalPeriod || "Initial Renewal Period Passed",
      subsequent: nextSubsequentRenewalPeriod,
    };
  };

  const handleSearch = async () => {
    const trimmedSerialNumber = serialNumber.trim();

    if (!trimmedSerialNumber) {
      alert("Please enter a trademark serial number.");
      return;
    }

    if (!email) {
      if (trimmedSerialNumber) {
        trackEvent("SearchButtonClick", {
          content_name: "Search Button",
          search_term: trimmedSerialNumber,
          ad_id: ad,
          campaign_id: campaign,
        });
      }
      setShowModal(true);
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/search`,
        {
          serialNumber: trimmedSerialNumber,
          email,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const {
        registrationDate,
        tm5Status,
        tm5StatusDesc,
        tm5StatusDef,
        status_status,
        image,
      } = response.data;

      const activeMatch = activeStatusCodes.find(
        (item) => item.code === parseInt(status_status)
      );
      const notActiveMatch = notActiveStatusCodes.find(
        (item) => item.code === parseInt(status_status)
      );

      if (activeMatch) {
        setStatusText("Active");
      } else if (notActiveMatch) {
        setStatusText("Not Active");
      } else {
        setStatusText("Pending");
      }

      setRegistrationDate(registrationDate || "N/A");
      setTm5Status(tm5Status || "N/A");
      setTm5StatusDesc(tm5StatusDesc || "N/A");
      setTm5StatusDef(tm5StatusDef || "N/A");

      const { initial, subsequent } = calculateRenewalDates(registrationDate);
      setInitialRenewal(initial);
      setSubsequentRenewal(subsequent);

      setImageData(image);

      setShowCard(true);
      setEmail("");
    } catch (error) {
      console.error("Error fetching data:", error);
      setShowCard(false);
    } finally {
      setLoading(false);
    }
  };

  const handleEmailSubmit = () => {
    trackEvent("SearchEmailButtonClick", {
      content_name: "Provided Email Button",
      ad_id: ad,
      campaign_id: campaign,
    });
    setShowModal(false);
    handleSearch();
  };

  return (
    <div>
      {/* <SEO
        title="Trademark Renewal & Protection | ZipRenewal"
        description="Simplify trademark renewals, manage infringement monitoring, and ensure reliable DMCA compliance. Start your protection journey with ZipRenewal."
        keywords="trademark renewal, trademark registration, infringement monitoring, DMCA compliance, brand protection"
      /> */}
      <header className={home_style.header}>
        <div className={home_style.logo}>
          <img src={logo} alt="Logo" />
        </div>
        <p className={home_style.tagline}>Trademark Renewal Made Simple</p>
      </header>
      <section className={home_style.hero}>
        <h2>Protect Your Trademark, Hassle-Free</h2>
        {/* <p>
          Stay protected without the hassle. Enter your serial number for an
          instant check.
        </p> */}
        <p className={home_style.inputCTA}>
          Don’t miss your renewal – <span>Check Your Dates Now!</span>
        </p>
        <div className={home_style.searchBar}>
          <input
            id="search"
            type="text"
            placeholder="Enter your trademark serial number"
            value={serialNumber}
            onChange={(e) => setSerialNumber(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch();
              }
            }}
          />
          <button onClick={handleSearch}>Check Renewal Dates</button>
          {loading && <div className={home_style.spinner}></div>}
        </div>

        {showCard && (
          <div className={home_style.resultCard}>
            <p>
              <strong>Status:</strong>{" "}
              <span
                style={{
                  color:
                    statusText === "Active"
                      ? "green"
                      : statusText === "Not Active"
                      ? "red"
                      : "orange",
                  fontWeight: "bold",
                }}
              >
                {statusText}
              </span>{" "}
              <br />
              {initialRenewal !== "Initial Renewal Period Passed" && (
                <>
                  <strong>Initial Renewal Window:</strong> {initialRenewal}{" "}
                  <br />
                </>
              )}
              {subsequentRenewal && (
                <>
                  <strong>Next Renewal Window:</strong> {subsequentRenewal}{" "}
                  <br />
                </>
              )}
              <strong>Registration Date:</strong> {registrationDate} <br />
            </p>
            <div className={home_style.tm_image}>
              <img
                src={imageData}
                alt="Trademark"
                style={{ width: "200px", height: "auto" }}
              />
            </div>
          </div>
        )}
      </section>
      <section className={home_style.features}>
        <div className={home_style.feature}>
          {" "}
          <img src={process} alt="Easy Process" />
          <h3>Easy Process</h3>
          <p>Start your renewal with one click—no legal expertise required.</p>
        </div>
        <div className={home_style.feature}>
          <img src={secure} alt="Secure" />
          <h3>Secure &amp; Reliable</h3>
          <p>
            Your information is protected with the highest industry standards.
          </p>
        </div>
        <div className={home_style.feature}>
          <img src={support} alt="Support" />
          <h3>Expert Support</h3>
          <p>
            Guidance from start to finish, so you’re never left in the dark.
          </p>
        </div>
      </section>
      {/* Modal for Email Input */}
      {showModal && (
        <div className={home_style.modal}>
          <div className={home_style.modalContent}>
            {/* Close button */}
            <button
              className={home_style.closeButton}
              onClick={() => setShowModal(false)}
            >
              ✖
            </button>
            <h2>Please Enter your email</h2>
            <input
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleEmailSubmit();
                }
              }}
            />
            <button
              className={home_style.submitButton}
              onClick={handleEmailSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      )}
      <footer className={home_style.footer}>
        <p>&copy; 2024 ZipRenewal. All rights reserved.</p>
        <br />
        <p>
          ZipRenewal is trusted by business owners nationwide for stress-free
          trademark protection.
        </p>
      </footer>
    </div>
  );
};

export default IndexPage;
